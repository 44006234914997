<template>
  <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modalRef">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button type="button" class="btn-close-top-right" data-bs-dismiss="modal" aria-label="Close"></button>
        <div class="modal-body text-center">
          <img v-if="modalType === 'success'" src="../../public/images/success.png" class="modal-icon" alt="Sucesso" />
          <img v-if="modalType === 'error'" src="../../public/images/error.png" class="modal-icon" alt="Erro" />
          <p class="modal-text">{{ modalMessage }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Modal } from 'bootstrap';
  export default {
    props: {
      modalMessage: {
        type: String,
        default: 'Ocorreu um erro ao processar sua solicitação.',
      },
      modalType: {
        type: String,
        default: 'error',
      }
    },
    methods: {
      showModal() {
        const modal = new Modal(this.$refs.modalRef);
        modal.show();
      },
      hideModal() {
        const modal = new Modal(this.$refs.modalRef);
        modal.hide();
      }
    }
  };
</script>

<style scoped>
.modal-content {
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  position: relative;
}

.btn-close-top-right {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 2rem;
  color: #6c757d;
  cursor: pointer;
  transition: color 0.3s ease;
  z-index: 100;
}

.btn-close-top-right:hover {
  color: #dc3545;
}

.btn-close-top-right:active {
  color: #a71d2a;
}

.btn-close-top-right::before {
  content: '×';
}

.modal-body {
  font-size: 18px;
  font-weight: 500;
  color: #333;
}

.modal-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 16px;
}

.modal-text {
  font-size: 18px;
  font-weight: 500;
  color: #333;
}

.modal-footer {
  border-top: none;
}
</style>
