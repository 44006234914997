<template>
  <form ref="fornecedorForm" @submit.prevent="submitForm">
    <!-- Dados Pessoais -->
    <DadosPessoais 
      v-model:tipoDocumento="tipoDocumento" 
      v-model:documento="documento" 
      v-model:nome="nome" 
      v-model:email="email" 
      v-model:nomeFantasia="nomeFantasia" 
      v-model:numeroInscricao="numeroInscricao"
      v-model:inscricaoMunicipal="inscricaoMunicipal"
      v-model:inscricaoEstadual="inscricaoEstadual"
      @documento-invalido="$emit('submit-error', $event)"
      @resetDocumento="resetDocumento"
    />

    <!-- Endereço -->
    <Endereco v-if="tipoDocumento === 'cpf'"
      v-model:endereco="endereco"
      v-model:cep="cep"
      v-model:ibge="ibge"
      v-model:numero="numero"
      v-model:complemento="complemento"
      v-model:bairro="bairro"
      v-model:cidade="cidade"
      v-model:estado="estado"
      @trigger-loading="$emit('trigger-loading')"
      @stop-loading="$emit('stop-loading')"
      @cep-invalido="$emit('submit-error', $event)"
    />

    <!-- Contato -->
    <Contato v-if="tipoDocumento === 'cpf'"
      v-model:ddd="ddd"
      v-model:telefone="telefone"
    />

    <!-- Dados Bancários -->
    <DadosBancarios 
      v-model:metodoPagamento="metodoPagamento"
      v-model:banco="banco"
      v-model:tipoConta="tipoConta"
      v-model:agencia="agencia"
      v-model:numeroConta="numeroConta"
      v-model:digitoVerificador="digitoVerificador"
      :tipoDocumento="tipoDocumento"
    />

    <!-- reCAPTCHA v2 -->
    <div class="form-group">
      <div id="recaptcha-container" class="g-recaptcha" 
           data-sitekey="6Le9wlcqAAAAAJVtk77RtGFCY56CskXt-LnhUbqT"
           data-callback="onCaptchaVerified"
           data-expired-callback="onCaptchaExpired"></div>
    </div>

    <!-- Botão de Enviar -->
    <div class="d-flex">
      <button type="submit" class="btn btn-primary" :disabled="!captchaToken">Finalizar Cadastro</button>
    </div>
  </form>
</template>

<script>
import DadosPessoais from './DadosPessoais.vue';
import Endereco from './Endereco.vue';
import Contato from './Contato.vue';
import DadosBancarios from './DadosBancarios.vue';
import { validacpf, validacnpj } from '../utils/utils';
import { bancos } from '../utils/data_banco';

export default {
  components: {
    DadosPessoais,
    Endereco,
    Contato,
    DadosBancarios
  },
  data() {
    return {
      tipoDocumento: 'cpf',
      documento: '',
      nome: '',
      email: '',
      nomeFantasia: '',
      numeroInscricao: '',
      inscricaoMunicipal: '',
      inscricaoEstadual: '',
      endereco: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      estado: '',
      cep: '',
      ibge: '',
      ddd: '',
      telefone: '',
      metodoPagamento: 'transferencia',
      numeroConta: '',
      digitoVerificador: '',
      banco: '',
      agencia: '',
      moeda: '',
      tipoConta: 'corrente',
      captchaToken: '', // Token do reCAPTCHA v2
      captchaError: false,
    };
  },
  methods: {
    async submitForm() {
      // Verifica se o captchaToken está presente antes de permitir o envio
      if (!this.captchaToken) {
        this.captchaError = true;
        return;
      }
      
      // Validação do CPF ou CNPJ
      if (this.tipoDocumento === 'cpf' && !validacpf(this.documento)) {
        this.$emit('submit-error', 'CPF inválido. Verifique o número e tente novamente.');
        return;
      }

      if (this.tipoDocumento === 'cnpj' && !validacnpj(this.documento)) {
        this.$emit('submit-error', 'CNPJ inválido. Verifique o número e tente novamente.');
        return;
      }

      // Validação de inscrição municipal e estadual. Ao menos uma das inscrições deve ser enviada
      if(this.tipoDocumento === 'cnpj' && !this.inscricaoEstadual && !this.inscricaoMunicipal){
        this.$emit('submit-error', 'É necessário informar inscrição municipal ou estadual!');
        return;
      }

      // Dados bancários obrigatórios quando a forma de pagamento é transferência
      if(this.metodoPagamento === "transferencia" && 
        (!this.numeroConta || !this.digitoVerificador || !this.banco || !this.agencia)){
          this.$emit('submit-error', 'Informe todos os dados bancários para tranferência!');
          return;
        }

      let bankname = bancos.find(banco => banco.value === this.banco);
      bankname = bankname ? bankname.name : '';

      try {
        this.$emit('trigger-loading');
        const response = await fetch(`${window.VUE_APP_BACKEND_URL}/api/fornecedor`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            tipoDocumento: this.tipoDocumento,
            documento: this.documento,
            email: this.email,
            inscricaoEstadual: this.inscricaoEstadual,
            inscricaoMunicipal: this.inscricaoMunicipal,
            endereco: {
              endereco: this.endereco,
              numero: this.numero,
              complemento: this.complemento,
              bairro: this.bairro,
              cidade: this.cidade,
              estado: this.estado,
              cep: this.cep,
              ibge: this.ibge
            },
            telefone: {
              ddd: this.ddd,
              numero: this.telefone,
            },
            pagamento: {
              metodo: this.metodoPagamento,
              banco: this.metodoPagamento === 'transferencia' ? {
                pais: this.paisBanco,
                numeroConta: this.numeroConta,
                digitoVerificador: this.digitoVerificador,
                banco: this.banco,
                nomeBanco: bankname,
                agencia: this.agencia,
                moeda: this.moeda,
                tipoConta: this.tipoConta,
              } : null,
            }
          }),
        });

        const data = await response.json();

        if (data.success) {
          this.$emit('submit-success', 'Cadastro realizado com sucesso!');
          // Resetar o formulário após o sucesso
          this.resetForm();
        } else {
          this.$emit('submit-error', 'Erro ao cadastrar - ' + data.message);
        }
        this.$emit('stop-loading');
      } catch (error) {
        console.error('Erro ao enviar o formulário:', error);
        this.$emit('submit-error', 'Erro ao conectar ao servidor. Tente novamente mais tarde.');
        this.$emit('stop-loading');
      }
    },
    resetForm() {
      // Resetar todos os campos do formulário
      this.tipoDocumento = 'cpf';
      this.documento = '';
      this.nome = '';
      this.email = '';
      this.nomeFantasia = '';
      this.numeroInscricao = '';
      this.inscricaoMunicipal = '';
      this.inscricaoEstadual = '';
      this.endereco = '';
      this.numero = '';
      this.complemento = '';
      this.bairro = '';
      this.cidade = '';
      this.estado = '';
      this.cep = '';
      this.ibge = '';
      this.ddd = '';
      this.telefone = '';
      this.metodoPagamento = 'transferencia';
      this.numeroConta = '';
      this.digitoVerificador = '';
      this.banco = '';
      this.agencia = '';
      this.moeda = '';
      this.tipoConta = 'corrente';
      this.captchaToken = '';
      this.captchaError = false;

      // Resetar o formulário HTML
      this.$refs.fornecedorForm.reset();

      // Resetar o reCAPTCHA
      if (window.grecaptcha) {
        grecaptcha.reset();
      }
    },
    onCaptchaVerified(response) {
      // Atualiza o token do captcha quando verificado
      this.captchaToken = response;
      this.captchaError = false;
    },
    onCaptchaExpired() {
      // Captura o evento de expiração e desativa o botão de submit
      this.captchaToken = '';
      this.captchaError = true;
    }
  },
  mounted() {
    // Renderizar o reCAPTCHA v2 e atribuir o callback onCaptchaVerified
    grecaptcha.ready(() => {
      grecaptcha.render('recaptcha-container', {
        sitekey: '6Le9wlcqAAAAAJVtk77RtGFCY56CskXt-LnhUbqT',
        callback: this.onCaptchaVerified,
        'expired-callback': this.onCaptchaExpired
      });
    });
  }
};
</script>
