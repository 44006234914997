<template>
  <div>
    <!-- Header -->
    <HeaderFornecedor />

    <!-- Formulário de Fornecedor -->
    <div class="container mt-1">
      <FornecedorForm 
        @submit-success="openModal('success', $event)" 
        @submit-error="openModal('error', $event)" 
        @trigger-loading="toggleLoading(true)"
        @stop-loading="toggleLoading(false)"  
      />
    </div>

    <!-- Modal Component -->
    <Modal :modalTitle="modalTitle" :modalMessage="modalMessage" :modalType="modalType" ref="modalRef" />

    <!-- Loading Component -->
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import HeaderFornecedor from './components/HeaderFornecedor.vue';
import FornecedorForm from './components/FornecedorForm.vue';
import Modal from './shared/Modal.vue';
import Loading from './shared/Loading.vue';

export default {
  name: 'App',
  components: {
    HeaderFornecedor,
    FornecedorForm,
    Modal,
    Loading
  },
  data() {
    return {
      modalTitle: '',
      modalMessage: '',
      modalType: '',
      isLoading: false // Controle de loading
    };
  },
  methods: {
    openModal(type, message) {
      if (type === 'success') {
        this.modalTitle = 'Sucesso!';
        this.modalMessage = message;
        this.modalType = 'success';
      } else {
        this.modalTitle = 'Erro!';
        this.modalMessage = message;
        this.modalType = 'error';
      }
      this.$refs.modalRef.showModal();
    },
    toggleLoading(status) {
      this.isLoading = status;
    }
  }
};
</script>
